import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ProductIcon from '@mui/icons-material/ShoppingCart';
import PolicyIcon from '@mui/icons-material/Policy';
import PublicIcon from '@mui/icons-material/Public';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import {
  MouseEventHandler,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useMemo,
} from 'react';
import { Fragment, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { PERMISSION } from 'constants/roles';
import { Link } from '@mui/material';
import _ from 'lodash';

interface TabItem {
  label: string;
  value: string;
  permission: number[];
  icon: ReactElement;
  children?: TabItem[];
}

export const AllPath: TabItem[] = [
  {
    label: 'Trang chủ',
    value: '/',
    permission: [],
    icon: <HomeIcon />,
  },
  {
    label: 'Banner',
    value: '/banner',
    permission: [PERMISSION.BANNER.VIEW],
    icon: <ViewCarouselIcon />,
  },
  {
    label: 'Khách hàng',
    value: '/customers',
    permission: [PERMISSION.CUSTOMER.VIEW, PERMISSION.BUY_ACCOUNT.VIEW],
    icon: <PeopleIcon />,
    children: [
      {
        label: 'Khách hàng',
        value: '/customers',
        permission: [PERMISSION.CUSTOMER.VIEW],
        icon: <PeopleIcon />,
      },
      {
        label: 'Tài khoản mua',
        value: '/buy-account',
        permission: [PERMISSION.BUY_ACCOUNT.VIEW],
        icon: <PeopleIcon />,
      },
    ],
  },
  {
    label: 'Tỷ giá và phí',
    value: '/exchange-fee',
    icon: <TrendingUpIcon />,
    permission: [
      PERMISSION.RATE_AND_CHARGE.RATE.VIEW,
      PERMISSION.RATE_AND_CHARGE.CHARGE.UPDATE,
      PERMISSION.RATE_AND_CHARGE.CHARGE.ADD,
      PERMISSION.RATE_AND_CHARGE.CHARGE.DELETE,
      PERMISSION.RATE_AND_CHARGE.CHARGE.EDIT,
    ],
    children: [
      {
        label: 'Tỷ giá',
        value: '/exchange-fee/exchange',
        icon: <TrendingUpIcon />,
        permission: [PERMISSION.RATE_AND_CHARGE.RATE.VIEW],
      },
      {
        label: 'Phí',
        value: '/exchange-fee/fee',
        icon: <TrendingUpIcon />,
        permission: [
          PERMISSION.RATE_AND_CHARGE.CHARGE.UPDATE,
          PERMISSION.RATE_AND_CHARGE.CHARGE.ADD,
          PERMISSION.RATE_AND_CHARGE.CHARGE.DELETE,
          PERMISSION.RATE_AND_CHARGE.CHARGE.EDIT,
        ],
      },
    ],
  },
  {
    label: 'Đơn hàng',
    value: '/orders',
    icon: <ShoppingCartIcon />,
    permission: [
      PERMISSION.ORDER.HOME.VIEW,
      PERMISSION.ORDER.CANCEL.VIEW,
      PERMISSION.ORDER.COMPLETE.VIEW,
      PERMISSION.ORDER.JP_EXPORT.VIEW,
      PERMISSION.ORDER.JP_IMPORT.VIEW,
      PERMISSION.ORDER.JP_INVENTORY.VIEW,
      PERMISSION.ORDER.JP_PENDING.VIEW,
      PERMISSION.ORDER.VN_EXPORT.VIEW,
      PERMISSION.ORDER.VN_IMPORT.ORDER.VIEW,
      PERMISSION.ORDER.VN_IMPORT.PACKAGE.VIEW,
      PERMISSION.ORDER.VN_WAITING_EXPORT.VIEW,
      PERMISSION.ORDER.WAIT_FOR_PAY.VIEW,
      PERMISSION.ORDER.WAIT_PROCESS.VIEW,
      PERMISSION.ORDER.COMPLAIN.VIEW,
      PERMISSION.ORDER.VN_INVENTORY.VIEW,
    ],
  },
  {
    label: 'Vùng miền',
    value: '/regions',
    icon: <PublicIcon />,
    permission: [PERMISSION.REGION.VIEW],
  },
  {
    label: 'Nhân viên',
    value: '/employees',
    icon: <PeopleIcon />,
    permission: [PERMISSION.EMPLOYEES.VIEW],
  },
  // quản lý sản phẩm ở đây nhé
  {
    label: 'Quản lý sản phẩm',
    value: '/product',
    icon: <ProductIcon />,
    permission: [PERMISSION.EMPLOYEES.VIEW],
  },
  {
    label: 'Phân quyền',
    value: '/roles',
    icon: <AdminPanelSettingsIcon />,
    permission: [PERMISSION.PERMISSIONS.VIEW],
  },
  {
    label: 'Chính sách và điều khoản',
    value: '/term-and-policy',
    icon: <AdminPanelSettingsIcon />,
    permission: [
      PERMISSION.POLICY_AND_RULES.POLICY.VIEW,
      PERMISSION.POLICY_AND_RULES.RULES.VIEW,
    ],
    children: [
      {
        label: 'Chính sách',
        value: '/term-and-policy/policy',
        icon: <PolicyIcon />,
        permission: [PERMISSION.POLICY_AND_RULES.POLICY.VIEW],
      },
      {
        label: 'Điều khoản',
        value: '/term-and-policy/term',
        icon: <PolicyIcon />,
        permission: [PERMISSION.POLICY_AND_RULES.RULES.VIEW],
      },
    ],
  },
];

const NavTabs = () => {
  const { pathname } = useLocation();
  const [value, setValue] = useState<string>('/');
  const { permission } = useAuth();

  // Tab components
  const tabs: TabItem[] = useMemo(() => {
    let tabData: TabItem[] = [];

    AllPath.forEach((item, index) => {
      if (
        item?.permission.length === 0 ||
        item?.permission.some((ele) => permission.includes(ele))
      ) {
        tabData.push({ ...item });
        if (item?.children) {
          const newChildren = item?.children.filter(
            (item) =>
              (item?.permission &&
                item?.permission.some((ele) => permission.includes(ele))) ||
              item?.permission === null
          );
          if (tabData[index]?.children) {
            tabData[index].children = newChildren;
          }
        }
      }
      if (item?.permission === null) {
        tabData.push({ ...item });
      }
    });

    tabData.forEach((item, index) => {
      if (item?.children) {
        const newChildren = item?.children.filter(
          (item) =>
            (item?.permission &&
              item?.permission.some((ele: number) =>
                permission.includes(ele)
              )) ||
            item?.permission === null
        );
        if (tabData[index]?.children && !_.isEmpty(newChildren)) {
          tabData[index].children = newChildren;
        }
      }
    });

    return tabData;
  }, [permission]);

  const handleChange = (_event: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  useEffect(() => {
    tabs.forEach((tab) => {
      if (tab.value !== '/' && pathname.includes(tab.value)) {
        setValue(tab.value);
      }
    });
  }, [pathname, tabs]);

  useEffect(() => {
    if (pathname.includes('/change-password')) {
      setValue('/');
    }
  }, [pathname]);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{ style: { display: 'none' } }}
      variant="scrollable"
      scrollButtons="auto"
      sx={{ minHeight: 'auto' }}
    >
      {tabs.map((tab, i) => (
        <NavTab key={i} {...tab} />
      ))}
    </Tabs>
  );
};

const NavTab = (props: TabItem) => {
  const { value, label, icon, children, ...rest } = props;
  const navigate = useNavigate();

  const [anchor, setAnchor] = useState<HTMLAnchorElement | null>(null);

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (children) {
      setAnchor(event.currentTarget);
    } else {
      navigate(value);
    }
  };
  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <Fragment>
      <Tab
        value={value}
        icon={icon}
        iconPosition="start"
        component={Link}
        onClick={handleClick}
        // to={children ? '#' : value}
        label={
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>
            {label}
          </Typography>
        }
        sx={{
          minHeight: 64,
          color: 'common.white',
          '&.Mui-selected': {
            bgcolor: 'common.white',
            color: 'primary.main',
          },
        }}
        {...rest}
      />
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        PaperProps={{ sx: { mt: 1 } }}
      >
        {children?.map((tab, i) => (
          <MenuItem
            key={i}
            onClick={handleClose}
            component={RouterLink}
            to={tab.value}
          >
            {tab.label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default NavTabs;
