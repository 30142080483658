import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Logout from 'components/Logout';
import { HEADER_HEIGHT } from 'constants/layouts';
import useAuth from 'hooks/useAuth';
import useMounted from 'hooks/useMounted';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logger from 'utils/Logger';
import NavTabs from './NavTabs';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProMenu from 'components/ProMenu';
import { PERMISSION } from 'constants/roles';
import Image from 'components/Image';

const Header = () => {
  const mounted = useMounted();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [openLogout, setOpenLogout] = useState<boolean>(false);
  const { permission } = useAuth();

  const handleOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    if (mounted.current) {
      setOpenLogout(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      Logger.log(error);
    }
  };

  const tabs = useMemo(() => {
    let tabData: any[] = [];
    const allTabs = [
      {
        label: 'Đổi mật khẩu',
        to: '/change-password',
        permission: [PERMISSION.CHANGE_PASSWORD],
      },
      {
        label: 'Đăng xuất',
        value: 'logout',
        onSelect: handleOpenLogout,
      },
    ];

    allTabs.forEach((item, index) => {
      if (
        !item?.permission ||
        item?.permission.length === 0 ||
        item?.permission.some((ele) => permission.includes(ele))
      ) {
        tabData.push({ ...item });
      }
    });

    return tabData;
  }, [permission]);

  return (
    <Fragment>
      <AppBar
        position='fixed'
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ minHeight: HEADER_HEIGHT, maxHeight: HEADER_HEIGHT }}>
          <Box sx={{ width: 130, height: 50, display: { xs: 'none', lg: 'block' } }}>
            <Image src={'/static/imgs/Snapbuy-White.png'} style={{ width: '100%', height: 'unset' }} />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <NavTabs />
          </Box>
          <Stack>
            <ProMenu items={tabs}>
              <IconButton color='inherit'>
                <AccountCircleIcon />
              </IconButton>
            </ProMenu>
          </Stack>
        </Toolbar>
      </AppBar>
      <Logout
        open={openLogout}
        onClose={handleCloseLogout}
        onSubmit={handleLogout}
        content={{
          label: t('Đăng xuất'),
          description: t('Bạn có muốn đăng xuất ngay bây giờ không?'),
          icon: LogoutIcon,
        }}
      />
    </Fragment>
  );
};

export default Header;
