import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Employees
const Product = Loadable(lazy(() => import('views/Product')));
const CreateProduct = Loadable(
  lazy(() => import('views/Product/CreateProduct'))
);
const EditProduct = Loadable(lazy(() => import('views/Product/EditProduct')));

const ProductRoutes: RouteObject = {
  path: 'product',
  element: <Outlet />,
  children: [
    { index: true, element: <Product /> },
    { path: 'create', element: <CreateProduct /> },
    { path: ':productId/edit', element: <EditProduct /> },
  ],
};

export default ProductRoutes;
