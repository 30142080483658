import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import NotFound from 'views/Errors/NotFound';

const Order = Loadable(lazy(() => import('views/Order')));
const OrderEdit = Loadable(lazy(() => import('views/Order/OrderDetail')));
const VerifyOrders = Loadable(lazy(() => import('views/Order/Verify')));
const NewBin = Loadable(lazy(() => import('views/Order/Bin/NewBin')));
const ExBin = Loadable(lazy(() => import('views/Order/Bin/ExistedBin')));

const VietNamImport = Loadable(
  lazy(() => import('views/Order/VietNam/VietNamImport'))
);
const VietNamExport = Loadable(
  lazy(() => import('views/Order/VietNam/NewVietNamExport'))
);
const VietNamOrderMatching = Loadable(
  lazy(() => import('views/Order/VietNam/VietNamOrderMatching'))
);
const JapanPending = Loadable(
  lazy(() => import('views/Order/Japan/JapanPending'))
);
const JapanExportOrderTable = Loadable(
  lazy(() => import('views/Order/Japan/JapanExport'))
);

const JapanImport = Loadable(
  lazy(() => import('views/Order/Japan/JapanImportMain'))
);

const CreateShipping = Loadable(
  lazy(() => import('views/Order/CreateShipping'))
);

const OrderMatchingList = Loadable(
  lazy(() => import('views/Order/VietNam/VietNamOrderMatchingList'))
);

const OrderRoutes: RouteObject = {
  path: 'orders',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <Order />,
    },
    {
      path: 'create-bin',
      element: <NewBin />,
    },
    {
      path: 'existed-bin',
      element: <ExBin />,
    },
    {
      path: 'export-order',
      element: <JapanExportOrderTable />,
    },
    {
      path: 'create-shipping',
      element: <CreateShipping />,
    },
    {
      path: 'import-japan',
      element: <JapanImport />,
    },
    {
      path: 'verify',
      element: <VerifyOrders />,
    },
    { path: 'import', element: <VietNamImport /> },
    {
      path: 'export',
      element: <Outlet />,
      children: [
        { index: true, element: <VietNamExport /> },
        { path: 'matching/:orderId', element: <VietNamOrderMatching /> },
      ],
    },
    {
      path: 'order-matching',
      element: <Outlet />,
      children: [
        { index: true, element: <OrderMatchingList /> },
        { path: ':orderId', element: <VietNamOrderMatching /> },
      ],
    },
    // {
    //   path: 'order-matching/:orderMatchingId',
    //   element: <VietNamOrderMatching />,
    // },
    { path: 'details', element: <OrderEdit /> },
    { path: 'edits', element: <OrderEdit /> },
    {
      path: 'import-excel',
      element: <JapanPending />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
};

export default OrderRoutes;
