// auth
export const API_LOGIN = '/auth/login';
export const API_LOGOUT = '/auth/logout';

// banner
export const API_GET_BANNER = '/banner/';
export const API_CREATE_BANNER = '/banner';
export const API_UPDATE_BANNER = '/banner';
export const API_BANNER_SEARCH = '/banner/search';
export const API_UNLOCK_BANNER = '/banner/lock/unlock/';

// upload file
export const API_UPLOAD_FILE_1 = '/file/upload';
export const API_UPLOAD_FILE_2 = '/file/upload-product-custom';

// customer
export const API_GET_CUSTOMER = '/customer/';
export const API_DISABLE_CUSTOMER = '/customer/disable/';
export const API_UPDATE_CUSTOMER = '/customer/admin/update/user/';
export const API_CUSTOMER_SEARCH = '/customer/search';

// employee
export const API_GET_PERSON_IN_CHARGE = '/customer/admin/getListEmployee';
export const API_SEARCH_EMPLOYEE = '/employee/search';
export const API_GET_EMPLOYEE = '/employee/';
export const API_DISABLE_EMPLOYEE = '/employee/';
export const API_CREATE_EMPLOYEE = '/employee/create';
export const API_UPDATE_EMPLOYEE = '/employee/update';

// profile
export const API_PROFILE = '/profile';

// address - regions
export const API_GET_CITIES = '/admin/address/city/list';
export const API_DISABLE_CITY = '/admin/address/city/lock/unlock/';
export const API_CREATE_CITY = '/admin/address/city/create';
export const API_UPDATE_CITY = '/admin/address/city/update';
export const API_GET_CITY_DETAILS = '/admin/address/city/';
export const API_GET_DISTRICTS = '/admin/address/district/list';
export const API_DISABLE_DISTRICT = '/admin/address/district/lock/unlock/';
export const API_CREATE_DISTRICT = '/admin/address/district/create';
export const API_UPDATE_DISTRICT = '/admin/address/district/update';
export const API_GET_DISTRICT_DETAIL = '/admin/address/district/';
export const API_GET_WARDS = '/admin/address/wards/list';
export const API_DISABLE_WARD = '/admin/address/wards/lock/unlock/';
export const API_CREATE_WARD = '/admin/address/wards/create';
export const API_UPDATE_WARD = '/admin/address/wards/update';
export const API_GET_WARD_DETAIL = '/admin/address/wards/';

// roles
export const API_GET_ROLES = '/roles';
export const API_GET_ACTIVE_ROLES = '/roles/actives';
export const API_CREATE_ROLES = '/roles/create';
export const API_UPDATE_ROLES = '/roles/update';
export const API_GET_ROLE_DETAIL = '/roles/';
export const API_DISABLE_ROLE = '/roles/lock-unlock/';
export const API_GET_PERMISSION_OF_ROLE = '/roles/permissions';

// term and policy
export const API_GET_TERMS = '/terms-and-privacy-policy/terms';
export const API_EDIT_TERMS = '/terms-and-privacy-policy/terms';
export const API_GET_POLICY = '/terms-and-privacy-policy/policy';
export const API_EDIT_POLICY = '/terms-and-privacy-policy/privacy';

// orders
export const API_GEN_PACKAGE_CODE = '/order/gen-package-code';
export const API_ORDER_MATCHING_SEARCH = '/order/vn-staff/match-orders/search';
export const API_MATCH_ORDER = '/order/vn-staff/match-orders';
export const API_CONFIRM_EXPORT_VN = '/order/confirm-export-vn-inventory';
export const API_CONFIRM_RETURN_VN =
  '/order/confirm-export-return-vn-inventory';
export const API_UPDATE_PRINT_CODE = '/order/request-permission/';
export const API_VALIDATE_IMPORT_VIETNAM_STOCK =
  'order/admin/vn-import/validate';
export const API_GET_VIETNAM_PENDING_LIST = 'order/admin/jp-exported/search-v2';

// currency rate
export const API_UPDATE_CURRENCY_RATE = '/admin/rate/update';
export const API_GET_CURRENCY_RATE = '/admin/rate/get-latest';
export const API_SEARCH_CURRENCY_RATE = '/admin/rate/search';

//fee
export const API_SAVE_DEFAULT_FEE = '/admin/fee/create';
export const API_CREATE_OTHER_FEE = '/admin/fee/other/create';
export const API_UPDATE_OTHER_FEE = '/admin/fee/other/update';
export const API_DELETE_OTHER_FEE = '/admin/fee/other/delete/';

export const API_GET_FEE = '/admin/fee';

//Process Order
export const API_GET_PROCESS_ORDER_BUY_ACCOUNT_LIST =
  '/process-order/all-buy-account';
export const API_GET_PROCESS_ORDER_CUSTOMER_CODE_LIST =
  '/process-order/all-customer-code';
