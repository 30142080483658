import { FCC } from '../types/react';
import { createContext, DispatchWithoutAction, useEffect, useState } from 'react';
import useRefresh from '../hooks/useRefresh';
import { getProcessOrderBuyerAccount } from '../services/processOrder';
import Logger from '../utils/Logger';
import useAuth from 'hooks/useAuth';

export interface BuyerAccountContextValue {
  buyerAccountList: string[];
  refetch: DispatchWithoutAction;
}

const BuyerAccountContext = createContext<BuyerAccountContextValue | null>(null);

if (process.env.REACT_APP_ENV === 'development') {
  BuyerAccountContext.displayName = 'BuyerAccountContext';
}
const BuyerAccountProvider: FCC = (props) => {
  const { children } = props;
  const [buyerAccount, setBuyerAccount] = useState<string[]>([]);
  const [refresh, refetch] = useRefresh();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        getProcessOrderBuyerAccount()
          .then((res) => {
            const { data } = res;
            setBuyerAccount(data || []);
          }).catch((err) => {
          Logger.log(err);
          setBuyerAccount([]);
        });
      })();
    }
  }, [refresh, isAuthenticated]);

  return (
    <BuyerAccountContext.Provider value={{ buyerAccountList: buyerAccount, refetch }}>
      {children}
    </BuyerAccountContext.Provider>
  );
};

const BuyerAccountConsumer = BuyerAccountContext.Consumer;
export {
  BuyerAccountContext as default,
  BuyerAccountProvider,
  BuyerAccountConsumer,
};
