import HttpClient from '../utils/HttpClient';
import { CommonResponse } from '../types/common';
import {
  API_GET_PROCESS_ORDER_BUY_ACCOUNT_LIST,
  API_GET_PROCESS_ORDER_CUSTOMER_CODE_LIST,
} from '../constants/api-path';

export const getProcessOrderCustomerCode = async () => {
  return HttpClient.get<null, CommonResponse<string[]>>(API_GET_PROCESS_ORDER_CUSTOMER_CODE_LIST);
};

export const getProcessOrderBuyerAccount = async () => {
  return HttpClient.get<null, CommonResponse<string[]>>(API_GET_PROCESS_ORDER_BUY_ACCOUNT_LIST);
}