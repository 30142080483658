// import { __DEV__ } from 'config';

// import process from 'node:process';

class Logger {
  private logger;

  constructor() {
    this.logger = console.log.bind(window.console);
  }

  public log(...args: any[]) {
    if (process.env.REACT_APP_ENV === 'development') {
      this.logger(...args);
    }
  }
}

export default new Logger();
