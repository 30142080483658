const STATUS_ACTIVE_ID = 1;
const STATUSES = [1, 2];

const ROLE_ENUMS = {
  ADMIN: 'ROLE_ADMIN',
  JAPAN_EMPLOYEE: 'Nhân viên kho nhật',
  VIETNAM_EMPLOYEE: 'Nhân viên kho việt',
  ACCOUNTANT: 'Kế toán',
  COORDINATOR: 'Nhân viên điều phối',
};

export const ROLES = {
  admin: 1,
  coordinator: 10,
  accountant: 9,
  vnEmployee: 8,
  jpEmployee: 6,
};

export const PERMISSION = {
  BANNER: {
    VIEW: 157,
    DETAIL: 158,
    ADD: 159,
    EDIT: 160,
    LOCK: 161,
  },
  REGION: {
    VIEW: 251,
    ADD: 252,
    EDIT: 253,
    LOCK: 254,
  },
  POLICY_AND_RULES: {
    POLICY: {
      VIEW: 264,
      EDIT: 265,
    },
    RULES: {
      VIEW: 266,
      EDIT: 267,
    },
  },
  CUSTOMER: {
    VIEW: 162,
    DETAIL: 163,
    EDIT: 164,
    LOCK: 165,
    RESET_PASS: 166,
    INPUT_MONEY: 167,
  },
  BUY_ACCOUNT: {
    VIEW: 162,
    EDIT: 162,
    CREATE: 162,
  },
  EMPLOYEES: {
    VIEW: 255,
    ADD: 256,
    DETAIL: 257,
    EDIT: 258,
    LOCK: 259,
    RESET_PASS: 321,
  },
  PERMISSIONS: {
    VIEW: 260,
    ADD: 261,
    DETAIL: 262,
    EDIT: 263,
    LOCK: 318,
  },
  RATE_AND_CHARGE: {
    CHARGE: {
      UPDATE: 170,
      ADD: 171,
      EDIT: 172,
      DELETE: 173,
      VIEW: 276,
    },
    RATE: {
      VIEW: 168,
      UPDATE: 169,
    },
  },
  ORDER_ACTIONS: {
    INPUT_EXCEL: 238,
    // IMPORT_JP: 239,
    EXPORT_JP: 240,
    IMPORT_VN: 241,
    MATCH_ORDER: 242,
    BIN: 243,
    SHIPPING: 244,
    EXPORT_VN: 245,
    // VERIFY_VN: 271,
  },
  ORDER_ACTIONS_SM: {
    IMPORT_JP: {
      EXCEL: {
        IMPORT: 239,
        EXPORT: 288,
      },
      NORMAL: {
        IMPORT: 290,
        EXPORT: 291,
      },
    },
    MATCH_ORDER: {
      DETAIL: 296,
      CONFIRM_MATCH: 297,
      RETURN: 298,
    },
    VERIFY_VN: {
      VIEW: 303,
      EDIT: 304,
      EXPORT_EXCEL: 305,
    },
  },
  ORDER_EXPORT_EXCEL: {
    INVENTORY_TRACKING_TABLE: 246,
    DELIVERY_BILL: 247,
    ESTIMATE_INVENTORY_TRACKING: 248,
    ACTUAL_INVENTORY_TRACKING: 249,
    CUSTOMER_FILES: 250,
  },
  ORDER: {
    HOME: {
      VIEW: 174,
      EXPORT_EXCEL: 175,
      CONFIRM_RECEIVED: 176,
      DETAIL: 178,
      EDIT: 179,
      CANCEL: 180,
      UPDATE_BUY_SUCCESS: 320,
    },
    WAIT_FOR_PAY: {
      VIEW: 187,
      EXPORT_EXCEL: 188,
      UPDATE_PAYMENT: 189,
      EDIT: 190,
      CANCEL: 191,
    },
    WAIT_PROCESS: {
      VIEW: 181,
      EXPORT_EXCEL: 182,
      CONFIRM_ORDER: 183,
      MERGE_ORDER: 184,
      EDIT: 185,
      CANCEL: 186,
      UPDATE_BUY_STATUS: 273,
      // BO 280
    },
    JP_PENDING: {
      VIEW: 192,
      EXPORT_EXCEL: 193,
      DETAIL: 194,
      EDIT: 195,
      CANCEL: 196,
      UPDATE_BILLCODE: 197,
      // 282
    },
    JP_IMPORT: {
      VIEW: 198,
      EXPORT_EXCEL: 199,
      DETAIL: 200,
      EDIT: 201,
      // CANCEL: 202,
      SYNC: 203,
    },
    JP_EXPORT: {
      VIEW: 204,
      EXPORT_EXCEL: 205,
      DETAIL: 206,
      EDIT: 207,
      // CANCEL: 208,
      SYNC: 209,
    },
    JP_INVENTORY: {
      VIEW: 210,
      EXPORT_EXCEL: 211,
      DETAIL: 212,
      EDIT: 213,
      // CANCEL: 214,
    },
    VN_IMPORT: {
      ORDER: {
        VIEW: 215,
        EXPORT_EXCEL: 216,
        DETAIL: 217,
        EDIT: 218,
      },
      PACKAGE: {
        VIEW: 219,
        EXPORT_EXCEL: 220,
        EDIT: 221,
      },
    },
    VN_WAITING_EXPORT: {
      VIEW: 222,
      EXPORT_EXCEL: 223,
      DETAIL: 224,
      EDIT: 225,
    },
    VN_EXPORT: {
      VIEW: 226,
      EXPORT_EXCEL: 227,
      DETAIL: 228,
    },
    VN_INVENTORY: {
      VIEW: 229,
      EXPORT_EXCEL: 230,
      DETAIL: 231,
      EDIT: 232,
    },
    COMPLETE: {
      VIEW: 233,
      EXPORT_EXCEL: 234,
      DETAIL: 235,
    },
    CANCEL: {
      VIEW: 236,
      EXPORT_EXCEL: 237,
    },
    COMPLAIN: {
      VIEW: 283,
      DETAIL: 284,
      EDIT: 285,
      REFUND: 286,
    },
  },
  CHANGE_PASSWORD: 268,
};

export { STATUS_ACTIVE_ID, STATUSES, ROLE_ENUMS };
