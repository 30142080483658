import type { FCC } from 'types/react';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logger from 'utils/Logger';
import wait from 'utils/wait';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ProForm from 'components/ProForm';
import ProFormContent from 'components/ProForm/ProFormContent';
import ProFormFooter from 'components/ProForm/ProFormFooter';
import ProFormGroup from 'components/ProForm/ProFormGroup';
import ProFormLabel from 'components/ProForm/ProFormLabel';
import ProFormSelect from 'components/ProForm/ProFormSelect';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ProFormHeader from 'components/ProForm/ProFormHeader';
import ActionButton from 'components/ProButton/ActionButton';
import Validation from 'utils/Validation';
import Grid from '@mui/material/Grid';
import Mixins from 'utils/Mixins';

type Operation = () => Promise<void> | void;

type Confirm = (data: ConfirmDeleteDialogData) => Promise<void> | void;

interface Options {
  id: number;
  headline?: string;
  onConfirm?: Confirm;
  onCancel?: Operation | false;
}

export interface ConfirmDeleteDialogData {
  cancelReasonId: number;
  id: number;
}

const schema = Validation.shape({
  cancelReasonId: Validation.select(1),
  id: Validation.number(),
});

export type DeleteDialogContextValue = (options: Options) => void;

export const DeleteDialogContext =
  createContext<DeleteDialogContextValue | null>(null);

const DeleteDialogProvider: FCC = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Options>({ id: 0 });

  const form = useForm<ConfirmDeleteDialogData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: schema.getDefault(),
  });

  useEffect(() => {
    if (open) {
      form.reset({
        ...schema.getDefault(),
        id: options.id,
      });
    }
  }, [open, form, options]);

  const handleConfirm = useCallback((options: Options) => {
    setOptions(options);
    setOpen(true);
  }, []);

  const handleClose = async () => {
    setOpen(false);
    await wait(350);
    setLoading(false);
  };

  const handleResolve = async (data: ConfirmDeleteDialogData) => {
    try {
      setLoading(true);
      if (options.onConfirm) {
        options.onConfirm(data);
      }
    } catch (error) {
      Logger.log(error);
    } finally {
      handleClose();
    }
  };

  const handleReject = () => {
    handleClose();
  };

  const { headline } = options;

  return (
    <DeleteDialogContext.Provider value={handleConfirm}>
      {children}
      <Dialog open={open} onClose={handleReject} maxWidth={'sm'} fullWidth>
        <DialogContent sx={{ p: 1.5 }}>
          <ProForm<ConfirmDeleteDialogData>
            form={form}
            grid
            onFinish={handleResolve}
          >
            <ProFormHeader>
              {headline || 'Bạn có chắc chắn muốn hủy đơn hàng này không?'}
            </ProFormHeader>
            <ProFormContent>
              <ProFormGroup>
                <Grid
                  container
                  alignItems="center"
                  columnSpacing={2}
                  rowSpacing={1}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <ProFormLabel
                      title={t('Lý do hủy')}
                      name="cancelReasonId"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <ProFormSelect
                      name="cancelReasonId"
                      placeholder={t('Vui lòng chọn lý do hủy')}
                      options={[
                        { label: 'Người bán hủy', value: 1 },
                        { label: 'Người mua hủy', value: 2 },
                        {
                          label: 'Quá hạn thanh toán',
                          value: 3,
                        },
                        { label: 'Hết hàng', value: 4 },
                        { label: 'Khác', value: 5 },
                      ]}
                      renderLabel={(option) => option.label}
                      renderValue={(option) => option.value}
                    />
                  </Grid>
                </Grid>
              </ProFormGroup>
            </ProFormContent>
            <ProFormFooter>
              <ActionButton
                actionType="return"
                loading={loading}
                type={'button'}
                onClick={handleReject}
              >
                {t('Hủy bỏ')}
              </ActionButton>
              <ActionButton
                actionType="check"
                loading={loading}
                type="submit"
                disabled={Mixins.Exists(form.formState.errors)}
              >
                {t('Xác nhận')}
              </ActionButton>
            </ProFormFooter>
          </ProForm>
        </DialogContent>
      </Dialog>
    </DeleteDialogContext.Provider>
  );
};

export default DeleteDialogProvider;
