import { FCC } from '../types/react';
import {
  createContext,
  DispatchWithoutAction,
  useEffect,
  useState,
} from 'react';
import useRefresh from '../hooks/useRefresh';
import { getProcessOrderCustomerCode } from '../services/processOrder';
import Logger from '../utils/Logger';
import useAuth from 'hooks/useAuth';

export interface CustomerCodeContextValue {
  customerCodeList: string[];
  refetch: DispatchWithoutAction;
}

const CustomerCodeContext = createContext<CustomerCodeContextValue | null>(
  null,
);

if (process.env.REACT_APP_ENV === 'development') {
  CustomerCodeContext.displayName = 'CustomerCodeContext';
}
const CustomerCodeProvider: FCC = (props) => {
  const { children } = props;
  const [customerCode, setCustomerCode] = useState<string[]>([]);
  const [refresh, refetch] = useRefresh();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        getProcessOrderCustomerCode()
          .then((res) => {
            const { data } = res;
            setCustomerCode(data || []);
          })
          .catch((err) => {
            Logger.log(err);
            setCustomerCode([]);
          });
      })();
    }
  }, [refresh, isAuthenticated]);

  return (
    <CustomerCodeContext.Provider
      value={{ customerCodeList: customerCode, refetch }}
    >
      {children}
    </CustomerCodeContext.Provider>
  );
};

const CustomerCodeConsumer = CustomerCodeContext.Consumer;
export {
  CustomerCodeContext as default,
  CustomerCodeProvider,
  CustomerCodeConsumer,
};
